.manage-mark-attendence {
    body {
        line-height: 1.6;
        margin: 2em;
    }

    th {
        background-color: #001f3f;
        color: #fff;
        padding: 0.5em 1em;
    }

    td {
        border-top: 1px solid #eee;
        padding: 0.5em 1em;
    }

    input {
        cursor: pointer;
    }

    /* Column types */
    th.missed-col {
        background-color: #f00;
    }

    td.missed-col {
        background-color: #ffecec;
        color: #f00;
        text-align: center;
    }

    .name-col {
        text-align: left;
    }
}