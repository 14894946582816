.login-form-component-root {
    --border-radius-1x: 2px;
    --border-radius-2x: calc(var(--border-radius-1x) * 2);

    --spacing-half: 4px;
    --spacing-1x: 8px;
    --spacing-2x: calc(var(--spacing-1x) * 2);
    --spacing-3x: calc(var(--spacing-1x) * 3);
    --spacing-4x: calc(var(--spacing-1x) * 4);

    --font-size-16: 16px;
    --login-form-el-height: 50px;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: #000000;

    height: 100%;
    min-width: 400px;
    margin: 0 auto;
}

.form-container {
    border-radius: var(--border-radius-2x);
    background-color: #ffffff;
    padding: var(--spacing-4x);
    box-sizing: border-box;
}

.form-container label {
    display: block;
    margin-bottom: var(--spacing-half);
    box-sizing: border-box;
}

.form-container input {
    height: var(--login-form-el-height);
    width: 100%;
    box-sizing: border-box;
    line-height: var(--login-form-el-height);
    font-size: var(--font-size-16);
    color: #000000;
    background-color: #f1f1f1;
    outline: none;
    padding: 0 var(--spacing-2x);
    cursor: text;
    border: none;
    border-radius: var(--border-radius-1x);
}

.login-btn {
    display: block;
    border: none;
    padding: 0;

    height: var(--login-form-el-height);
    width: 100%;
    text-align: center;
    line-height: var(--login-btn-height);
    font-size: var(--font-size-16);
    border-radius: 0 0 10px 10px;
    margin: 0 0 var(--spacing-2x);

    cursor: pointer;
    font-weight: 500;
    background-color: #303030;
    color: #f1f1f1;
}

.rive-wrapper {
    width: 100%;
}

.rive-container {
    min-height: 300px;
    min-width: 400px;
    width: 100%;
    margin: 0 auto;
}

.login-form-wrapper {
    width: 100%;
    max-width: 400px;
}

.rive-story-container-login {
    background-color: #d6e2ea;
    min-height: 100vh;
}